<template>
  <div>
    <div class="flex-container">
      <div v-for="(item, index) in items" :key="index">
        <div
          :class="fadeIn"
          class="relative pointer card-margin"
          @mouseleave="handleLeave(item, index)"
          @mouseenter="handleEnter(item, index)"
          @click="handlePreview(item)"
        >
          <el-image
            ref="workCard"
            v-if="
              item.src[0].includes('jpg') ||
              item.src[0].includes('jpeg') ||
              item.src[0].includes('png')
            "
            fit="cover"
            class="item"
            :src="item.src[0]"
          />
          <video
            ref="workCard"
            v-else
            class="item"
            muted
            playsinline
            webkit-playsinline
          >
            <source :src="item.src[0]" type="video/mp4" />
          </video>
          <transition name="fade" mode="out-in">
            <div
              v-if="item.detailShow"
              class="card-padding title-show backdrop-3 text-white"
            >
              <div class="text-xl text-bold">
                {{ handleWorkType(item.route) }}作品｜{{ item.title }}
              </div>
              <div
                class="text-sm margin-top-xs text-cut-5"
                v-html="item.description ? item.description : item.title"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";

export default {
  mixins: [mainCommon],
  props: {
    items: {
      require: true,
      default: [],
    },
  },
  watch: {
    items() {
      if (!this.deviceMode) return;
      this.items.forEach((res) => {
        res.detailShow = true;
      });
    },
  },
  methods: {
    handleWorkType(route) {
      if (route === "/web-work") return "網站";
      if (route === "/mg-work") return "MG 動畫";
      if (route === "/concert-work") return "演唱會";
      if (route === "/ios-work") return "iOS";
    },
    handleItemVideo(all, type, index) {
      if (all)
        return this.items.forEach((res, idx) => {
          if (type === "pause") this.$refs.workCard[idx].pause();
          if (type === "play") this.$refs.workCard[idx].play();
        });
      if (type === "pause") this.$refs.workCard[index].pause();
      if (type === "play") this.$refs.workCard[index].play();
    },
    handleEnter(item, index) {
      if (this.deviceMode) return;
      item.detailShow = true;
      if (item.src[0].includes(".mp4"))
        this.handleItemVideo(false, "play", index);
    },
    handleLeave(item, index) {
      if (this.deviceMode) return;
      item.detailShow = false;
      if (item.src[0].includes(".mp4"))
        this.handleItemVideo(false, "pause", index);
    },
    handlePreview(item) {
      this.$emit("handlePreview", item);
    },
  },
};
</script>

<style lang="less" scoped>
.flex-container {
  width: 100%;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-around;
  font-size: 0;
}
.item {
  width: 33vw;
  height: 40vh;
  object-fit: cover;
  // transition: all 1s ease-in-out;
  // -webkit-transition: all 1s ease-in-out;
  // &:hover {
  //   transform: scale(1.2);
  //   -ms-transform: scale(1.2);
  //   -webkit-transform: scale(1.2);
  // }
}
.title-show {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
}
.card-padding {
  padding: 30px;
}
.card-margin {
  margin-bottom: 5px;
}

@media screen and (max-width: 659px) {
  .flex-container {
    display: unset;
  }
  .item {
    width: 100%;
    height: 300px;
    margin: 0;
  }
  .card-padding {
    padding: 20px;
  }
  .card-margin {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 660px) and (max-width: 1366px) {
  .flex-container {
    // display: unset;
  }
  .item {
    width: 49.5vw;
    height: 350px;
  }
  .card-margin {
    // margin-bottom: 7px;
  }
}
</style>