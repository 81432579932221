var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"width":_vm.width,"title":_vm.previewData ? _vm.previewData.title : '',"close-on-click-modal":"","visible":_vm.dialog,"destroy-on-close":"","append-to-body":""},on:{"close":_vm.handleClose}},[_vm._l((_vm.previewData ? _vm.previewData.src : []),function(srcItem,index){return _c('div',{key:index},[(
          srcItem.includes('jpg') ||
          srcItem.includes('jpeg') ||
          srcItem.includes('png')
        )?_c('el-image',{staticClass:"image",class:_vm.fadeIn,attrs:{"src":srcItem,"fit":"cover"}}):_c('video',{staticClass:"video",attrs:{"playsinline":"","webkit-playsinline":"","muted":"","controls":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":srcItem,"type":"video/mp4"}})])],1)}),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(
        _vm.previewData
          ? _vm.previewData.description
            ? _vm.previewData.description
            : _vm.previewData.title
          : ''
      )}}),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"material-icons cancel-btn pointer",on:{"click":_vm.handleClose}},[_vm._v(" cancel ")])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }