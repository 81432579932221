<template>
  <div>
    <el-dialog
      :width="width"
      :title="previewData ? previewData.title : ''"
      close-on-click-modal
      :visible="dialog"
      @close="handleClose"
      destroy-on-close
      append-to-body
    >
      <div
        v-for="(srcItem, index) in previewData ? previewData.src : []"
        :key="index"
      >
        <el-image
          v-if="
            srcItem.includes('jpg') ||
            srcItem.includes('jpeg') ||
            srcItem.includes('png')
          "
          class="image"
          :class="fadeIn"
          :src="srcItem"
          fit="cover"
        />
        <video
          class="video"
          v-else
          playsinline
          webkit-playsinline
          muted
          controls
        >
          <source :src="srcItem" type="video/mp4" />
        </video>
      </div>
      <div
        class="description"
        v-html="
          previewData
            ? previewData.description
              ? previewData.description
              : previewData.title
            : ''
        "
      />
      <div class="text-center">
        <span @click="handleClose" class="material-icons cancel-btn pointer">
          cancel
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import configCommon from "@/utils/configCommon";

export default {
  mixins: [mainCommon],
  props: {
    previewData: {
      require: true,
    },
    dialog: {
      require: true,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose", this.previewData);
    },
  },
  computed: {
    width() {
      return !this.deviceMode ? "" : "90vw";
    },
  },
};
</script>

<style lang="less" scoped>
.description {
  padding: 30px 0 50px 0;
}
.image {
  width: 100%;
  margin-bottom: 20px;
}
.video {
  width: 100%;
  height: 45vh;
  margin-bottom: 20px;
}
.cancel-btn {
  font-size: 60px;
}

@media screen and (max-width: 659px) {
  .image {
    // margin-bottom: 10px;
  }
  .video {
    height: 25vh;
  }
  .cancel-btn {
    font-size: 70px;
  }
}
</style>